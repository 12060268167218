import { FC } from "react";

import { Box, Flex, Stack, Text, useBreakpointValue, Link as ChakraLink } from "@chakra-ui/react";
import Link from "next/link";

import { Heading } from "@components/Heading/Heading";
import Image from "@components/Image";
import { IBlogCategory } from "@features/types";

import CategoryTag from "./CategoryTag";
import PublishDate from "./PublishDate";

interface ILatestPostProps {
  category: IBlogCategory;
  imageSrc?: string;
  title: string;
  shortSummary: string;
  publishDate: string;
  slug: string;
}

const LatestPost: FC<ILatestPostProps> = ({ category, imageSrc, publishDate, shortSummary, title, slug }) => {
  const gradient = useBreakpointValue(
    { base: "linear(to-t, blackAlpha.800,  blackAlpha.800)", md: "linear(to-t, blackAlpha.800, transparent)" },
    { ssr: true }
  );
  const fontColor = useBreakpointValue({ base: "white", md: "gray.300" }, { ssr: true });

  return (
    <Flex
      as="article"
      h={{ base: "444px", md: "500px" }}
      w="full"
      flex={1}
      borderRadius="md"
      position="relative"
      bgGradient={gradient}
    >
      {imageSrc && (
        <Box zIndex={-1}>
          <Image
            src={imageSrc}
            layout="fill"
            objectFit="cover"
            alt=""
            borderRadius="md"
            loading="lazy"
            draggable={false}
          />
        </Box>
      )}
      <Stack
        h="full"
        flex={1}
        spacing={0}
        justifyContent={{ base: "space-between", md: "flex-end" }}
        p={8}
        color="white"
      >
        <Box>
          <Box mb={4}>
            <CategoryTag href={`/${category.slug}`} name={category.name} isLatest />
          </Box>
          <Box pb={8}>
            <Heading
              fontSize="desktopHeadingSize.3"
              textTransform="capitalize"
              as="h3"
              mb={2}
              lineHeight={{ base: "40px", md: undefined }}
            >
              <Link href={`/${slug}`} passHref scroll>
                <ChakraLink as="span">{title}</ChakraLink>
              </Link>
            </Heading>
            <Text color={fontColor} fontSize="textSize.regular" lineHeight="24px">
              {shortSummary}
            </Text>
          </Box>
        </Box>
        <PublishDate date={publishDate} textProps={{ color: "white" }} />
      </Stack>
    </Flex>
  );
};

export default LatestPost;
