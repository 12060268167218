import React, { FC, useState } from "react";

import dynamic from "next/dynamic";

import AssistantResults from "@components/Assistant";
import Hero from "@components/homepage/Hero";
import HTMLParser from "@components/HTMLParser/HTMLParser";
import ReferralProductsList from "@components/product/ReferralProductsList";
import InfoMessage from "@components/Referral/InfoMessage/InfoMessage";
import Block from "@components/ResourceView/components/Block";
import Page from "@components/ResourceView/components/Page/Page";
import { IPageData } from "@components/ResourceView/types";
import { IBlock, IPage, ISection, ResourceType } from "@features/cms/types";
import { TConfig } from "@features/config/types";
import { IVehicleLookUpCarInfo } from "@features/vehicles/types";
import { generateId } from "@utils/utils";

import { BlockWithImage } from "./components/BlockWithImage";
import Section from "./components/Section";

const ReferralTopSection = dynamic(() => import("@components/Referral/ReferralTopSection"), { ssr: false });
const CarPurchaseAssistantForm = dynamic(() => import("@components/forms/CarPurchaseAssistantForm"), { ssr: false });

export const REFERRAL_PRODUCTS_SECTION = "referral-products";
export const REFERRAL_HEADER_SECTION = "referral-top-block";
export const CPA_SECTION = "car-purchase-assistant-form";
export const CPA_SECTION_RESULT = "car-purchase-assistant-result";

interface IResourceViewProps {
  resource: IPage | ISection | IBlock;
  pageData?: IPageData;
  config?: TConfig;
}

const ResourceView: FC<IResourceViewProps> = ({ resource, pageData, config }) => {
  const [responseData, setResponseData] = useState<IVehicleLookUpCarInfo | undefined>(undefined);

  const renderBlock = (block: IBlock) => {
    const { image } = block;

    if (image) {
      return (
        // In `block`, property `image` still appears as `undefined`. Providing checked `image` to the component.
        <BlockWithImage
          key={block.code}
          block={{ ...block, image }}
          hasSideImagePlaceholder={block.html.includes("side-image-placeholder")}
        >
          <HTMLParser html={block.html} />
        </BlockWithImage>
      );
    }

    return (
      <Block key={block.code} block={block}>
        <HTMLParser html={block.html} />
      </Block>
    );
  };

  const renderSection = (section: ISection) => {
    if (section.desktop.heroBlock) {
      const blockWithObjectImageIndex = section.desktop.blocks.findIndex((block) => block.image);
      const objectImage = section.desktop.blocks[blockWithObjectImageIndex]?.image;
      const blocks = section.desktop.blocks.map((block, index) =>
        index === blockWithObjectImageIndex
          ? {
              ...block,
              image: undefined,
            }
          : block
      );

      return (
        <Hero key={generateId(10)} background={section.desktop.image?.path} object={objectImage?.path}>
          {blocks.map(renderBlock)}
        </Hero>
      );
    }

    if (section.code === REFERRAL_HEADER_SECTION) {
      const blockWithObjectImageIndex = section.desktop.blocks.findIndex((block) => block.image);
      const objectImage = section.desktop.blocks[blockWithObjectImageIndex]?.image;
      const blocks = section.desktop.blocks.map((block, index) =>
        index === blockWithObjectImageIndex
          ? {
              ...block,
              image: undefined,
            }
          : block
      );

      return (
        <ReferralTopSection key={generateId(10)} background={section.desktop.image?.path} object={objectImage?.path}>
          {blocks.map(renderBlock)}
        </ReferralTopSection>
      );
    }

    if (section.code === REFERRAL_PRODUCTS_SECTION) {
      return (
        <Section key={section.code} section={section}>
          <ReferralProductsList
            products={pageData?.referralProducts || []}
            isLoading={false}
            code={pageData?.referralCode}
            isCodeValid={pageData?.isReferralCodeValid}
          />
        </Section>
      );
    }

    if (section.code === CPA_SECTION) {
      return (
        <Section key={section.code} section={section}>
          <CarPurchaseAssistantForm
            image={section.desktop.blocks[0].image?.path}
            setResponseData={setResponseData}
            captchaSiteKey={config?.google_captcha_site_key || ""}
          />
        </Section>
      );
    }

    if (section.code === CPA_SECTION_RESULT) {
      if (!(responseData && responseData?.data && responseData.data.data?.faults?.length > 0)) {
        return null;
      }

      return (
        <Section key={section.code} section={section}>
          <AssistantResults data={responseData.data} vin={responseData.vin} />
        </Section>
      );
    }

    return (
      <Section key={section.code} section={section}>
        {section.desktop.blocks.map(renderBlock)}
      </Section>
    );
  };

  const renderPage = (page: IPage, data: IPageData) => (
    <Page pageName={page.name} pageType={page.resourceCategory} pageData={data}>
      {pageData?.infoMessage && pageData?.infoMessage.trim() !== "" && (
        <InfoMessage message={pageData?.infoMessage} isValid={pageData?.isReferralCodeValid} />
      )}
      {page.sections.length > 0 && page.sections.map(renderSection)}
    </Page>
  );

  switch (resource.resourceType) {
    case ResourceType.BLOCK:
      return renderBlock(resource as IBlock);
    case ResourceType.SECTION:
      return renderSection(resource as ISection);
    case ResourceType.PAGE:
      return renderPage(resource as IPage, pageData || {});
    default:
      return null;
  }
};

export default ResourceView;
