import { FC } from "react";

import { HStack, Text } from "@chakra-ui/react";
import Link from "next/link";
import { FaChevronRight } from "react-icons/fa";

type TSideMenuItemProps = {
  path: string;
  isActive: boolean;
  title?: string;
};

const SideMenuItem: FC<TSideMenuItemProps> = ({ path, isActive, title }) => {
  return (
    <Link href={path} passHref>
      <HStack fontSize="textSize.regular" color="gray.400" w="full" justify="space-between" py="2" cursor="pointer">
        <Text color="black" fontWeight={isActive ? "bold" : "normal"}>
          {title}
        </Text>
        <FaChevronRight />
      </HStack>
    </Link>
  );
};

export default SideMenuItem;
