import { StyleProps } from "@chakra-ui/react";

import { IBlock } from "features/cms/types";
import borderRadius from "theme/foundations/borders";

const borderRadiusValue = borderRadius.radii.md;
const baseContainerPadding: StyleProps["padding"] = {
  base: 2,
  md: 4,
  xl: 6,
};

interface IBlockStyles {
  containerBorderRadiusByImage: StyleProps | undefined;
  containerDirection: StyleProps["flexDir"];
  paddingByImagePosition: StyleProps;
}

type TBlockStyleData = Pick<
  IBlock,
  | "desktopBlockImagePosition"
  | "mobileBlockImagePosition"
  | "roundedBlockBorder"
  | "roundedBlockImageBorder"
  | "blockTextAreaPadding"
>;

/**
 * Block with image desktop styles by block data.
 */
const getDesktopStyles = ({
  desktopBlockImagePosition,
  blockTextAreaPadding,
  roundedBlockImageBorder,
}: TBlockStyleData): IBlockStyles => {
  const isDesktopImageLeft = desktopBlockImagePosition === "left";
  const childrenContainerPadding = blockTextAreaPadding ?? baseContainerPadding;

  const borderRadiusByPosition: StyleProps = isDesktopImageLeft
    ? { borderStartRadius: borderRadiusValue }
    : { borderEndRadius: borderRadiusValue };

  const containerBorderRadiusByImage: StyleProps | undefined = roundedBlockImageBorder
    ? borderRadiusByPosition
    : undefined;

  const containerDirection: StyleProps["flexDir"] = isDesktopImageLeft ? "row" : "row-reverse";

  const paddingByImagePosition: StyleProps = isDesktopImageLeft
    ? { pl: 0, pr: childrenContainerPadding }
    : { pr: 0, pl: childrenContainerPadding };

  return {
    containerDirection,
    paddingByImagePosition: { ...paddingByImagePosition, py: childrenContainerPadding },
    containerBorderRadiusByImage,
  };
};

/**
 * Block with image mobile styles by block data.
 */
const getMobileStyles = ({
  mobileBlockImagePosition,
  blockTextAreaPadding,
  roundedBlockImageBorder,
}: TBlockStyleData): IBlockStyles => {
  const isMobileImageTop = mobileBlockImagePosition === "top";
  const childrenContainerPadding = blockTextAreaPadding ?? baseContainerPadding;

  const borderRadiusByPosition: StyleProps = isMobileImageTop
    ? { borderTopRadius: borderRadiusValue }
    : { borderBottomRadius: borderRadiusValue };

  const containerBorderRadiusByImage: StyleProps | undefined = roundedBlockImageBorder
    ? borderRadiusByPosition
    : undefined;

  const containerDirection: StyleProps["flexDir"] = isMobileImageTop ? "column" : "column-reverse";

  const paddingByImagePosition: StyleProps = isMobileImageTop
    ? { pt: 0, pb: childrenContainerPadding }
    : { pb: 0, pt: childrenContainerPadding };

  return {
    containerDirection,
    paddingByImagePosition: { ...paddingByImagePosition, px: childrenContainerPadding },
    containerBorderRadiusByImage,
  };
};

type TGetBlockWithImageStylesReturn = {
  imageBorderRadiusStyles?: StyleProps;
  blockContainer: StyleProps;
  paddingByImagePosition;
};

/**
 * Returns styles props by certain block property for specific block element.
 */
export const getBlockWithImageStyles = (
  blockData: TBlockStyleData,
  isMobile?: boolean
): TGetBlockWithImageStylesReturn => {
  const { roundedBlockBorder: isBlockRounded, roundedBlockImageBorder: isImageRounded } = blockData;
  const { containerDirection, paddingByImagePosition, containerBorderRadiusByImage } = isMobile
    ? getMobileStyles(blockData)
    : getDesktopStyles(blockData);
  const imageBorderRadiusStyles = isImageRounded ? { borderRadius: borderRadiusValue } : undefined;
  const containerBorderRadius = isBlockRounded ? { borderRadius: borderRadiusValue } : containerBorderRadiusByImage;

  return {
    imageBorderRadiusStyles,
    blockContainer: {
      flexDir: containerDirection,
      ...containerBorderRadius,
    },
    paddingByImagePosition,
  };
};
