import { useEffect, useState } from "react";

import { useRouter } from "next/router";

import { DATE_LOCALES } from "@configs/translations";
import { formatDate, IDateToFormatParams } from "@utils/date-utils";

type TUseDateFormatReturnType = (date: string, dateFormat: IDateToFormatParams["formatKey"]) => string;

/**
 * Format date based on user locale.
 *
 * @param shouldInit - import `date-fns` on `locale/load` and save it locale based of users.
 * NOTE: Param value should be `false` when it is inside array component to save performance.
 */
const useDateFormat = (shouldInit = true): TUseDateFormatReturnType => {
  const { locale } = useRouter();
  const [currLocale, setCurrLocale] = useState<Locale | undefined>(undefined);

  useEffect(() => {
    if (!shouldInit) {
      return;
    }

    const importLocale = DATE_LOCALES[locale as string] || DATE_LOCALES.en;

    (async () => {
      const lang = await import(`date-fns/locale/${importLocale}/index.js`);

      setCurrLocale(lang);
    })();
  }, [locale, shouldInit]);

  return (date: string, dateFormat: IDateToFormatParams["formatKey"]) =>
    formatDate({ date, locale: currLocale, formatKey: dateFormat ?? "default" });
};

export default useDateFormat;
