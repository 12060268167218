import React, { FC } from "react";
import useTranslation from "next-translate/useTranslation";

import { Button, ButtonProps } from "@chakra-ui/react";

import CompareIcon from "@assets/icons/Compare.svg";

const CompareButton: FC<{
  type: string;
  onClick: Pick<ButtonProps, "onClick">["onClick"];
}> = ({ onClick, type }) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={onClick}
      py={0}
      px={0}
      mt={7}
      variant="clean"
      size="sm"
      fontWeight="bold"
      leftIcon={<CompareIcon />}
    >
      {t(`compare:compare-${type}`)}
    </Button>
  );
};

export default CompareButton;
