import { FC } from "react";

import { SimpleGrid } from "@chakra-ui/react";

const Grid: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <SimpleGrid
      columns={{
        base: 1,
        sm: 2,
        lg: 3,
        xl: 4,
      }}
      gap={{
        base: 4,
        lg: 6,
      }}
    >
      {children}
    </SimpleGrid>
  );
};

export default Grid;
