import { FC } from "react";

import TickIcon from "@assets/icons/Tick.svg";

import Dash from "../Dash";

type TFeatureStatus = {
  isEnabled: boolean;
};

const FeatureStatus: FC<TFeatureStatus> = ({ isEnabled }) => {
  if (isEnabled) {
    return <TickIcon />;
  }

  return <Dash />;
};

export default FeatureStatus;
