export const border = {
  borderBottom: "1px solid",
  borderColor: "gray.200",
};

export const borderTop = {
  borderTop: border.borderBottom,
  borderColor: border.borderColor,
};

export const gridItemBaseStyles = {
  base: {
    colSpan: 1,
  },
  md: {
    colSpan: 1,
  },
};

export const gridStyles = {
  placeholder: {
    p: 0,
  },
  item: {
    base: {
      ...gridItemBaseStyles.base,
    },
    md: {
      ...gridItemBaseStyles.md,
    },
  },
  border: {
    base: {
      ...border,
      py: 4,
    },
    md: {
      ...border,
      py: 5,
    },
  },
};
