import { FC } from "react";
import useTranslation from "next-translate/useTranslation";

import { Accordion, ExpandedIndex } from "@chakra-ui/react";

import { TProductsComparison } from "@modules/Compare/types";

import Layout from "../Layout";
import type { TTabOnChange } from "../Tabs";
import Group from "./Group";

interface IProductsProps {
  data: TProductsComparison[];
  handleTabChange?: TTabOnChange;
  onExpand?: (expandedIndex: ExpandedIndex) => void;
  expandedIndex?: ExpandedIndex;
}

const Products: FC<IProductsProps> = ({ data, handleTabChange, onExpand, expandedIndex }) => {
  const { t } = useTranslation();

  const productsData = data[0];

  const getDefaultIndex = () => {
    if (!expandedIndex) {
      return [];
    }

    return typeof expandedIndex === "number" ? [expandedIndex] : expandedIndex;
  };

  const defaultIndex = getDefaultIndex();

  return (
    <Layout title={t("compare:products.title")}>
      <Accordion defaultIndex={defaultIndex} allowMultiple onChange={onExpand}>
        {Object.entries(productsData).map(([taxon, value]) => {
          return <Group key={`group-${taxon}`} data={value} taxon={taxon} handleTabChange={handleTabChange} />;
        })}
      </Accordion>
    </Layout>
  );
};

export default Products;
