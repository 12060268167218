import { FC } from "react";

import { Box } from "@chakra-ui/react";

const Dash: FC = () => {
  return (
    <Box
      w="24px"
      height="24px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      _after={{
        content: '""',
        width: "13px",
        height: "2px",
        bg: "gray.200",
        display: "block",
      }}
    />
  );
};

export default Dash;
