import { range } from "@utils/utils";

interface IPaginationConfig {
  currentPage: number;
  numberOfPages: number;
  /**
   * Number of visible pages before/after current page.
   */
  visibleSiblingsCount?: number;
}

// Visible page number count from start and end
const ALWAYS_VISIBLE = 2;

export const generatePagination = ({
  currentPage,
  numberOfPages,
  visibleSiblingsCount = 1,
}: IPaginationConfig): number[] => {
  // Total of visible page count
  const visiblePageCount = visibleSiblingsCount + ALWAYS_VISIBLE;
  // `+1` includes sibling for start due array indexing from `0`.
  const startRangeStopAt = visiblePageCount + 1;
  const endRangeStartAt = numberOfPages - visiblePageCount;

  const startRange = range(1, startRangeStopAt);
  const endRange = range(endRangeStartAt, numberOfPages);

  if (startRangeStopAt >= endRangeStartAt) {
    return range(1, numberOfPages);
  }

  // NOTE: `-1` indicates where `ellipsis` should be rendered.
  if (startRange.includes(currentPage)) {
    const startLastSibling = startRangeStopAt + visibleSiblingsCount;
    // Add missing sibling if is missing from array that depends from `visibleSiblingsCount`.
    const additionalSibling = startRange.at(-1) === startLastSibling ? [] : [startLastSibling];

    return [...startRange, ...additionalSibling, -1, numberOfPages];
  }

  if (endRange.includes(currentPage)) {
    const endFirstSibling = endRangeStartAt - visibleSiblingsCount;
    // Add missing sibling if is missing from array that depends from `visibleSiblingsCount`.
    const additionalSibling = endRange.at(0) === endFirstSibling ? [] : [endFirstSibling];

    return [1, -1, ...additionalSibling, ...endRange];
  }

  return [1, -1, ...range(currentPage - visibleSiblingsCount, currentPage + visibleSiblingsCount), -1, numberOfPages];
};
