/* eslint-disable react/no-array-index-key */
import { FC } from "react";

import { Box, BoxProps, GridItem, useBreakpointValue } from "@chakra-ui/react";

import Dash from "../Dash";
import { gridStyles } from "../styles";

type TOption = string | boolean;

type TDataLine = {
  options: TOption[];
  styles?: BoxProps;
};

const DataLine: FC<TDataLine> = ({ options, styles }) => {
  const gridItemStyles = useBreakpointValue(gridStyles.item);
  const borderStyles = useBreakpointValue(gridStyles.border);

  return (
    <>
      {options.map((option, index) => {
        return (
          <GridItem {...borderStyles} {...gridItemStyles} key={`option-${index}-${option}`}>
            <Box textAlign="center" alignItems="center" justifyContent="center" display="flex" {...(styles ?? {})}>
              {!option ? <Dash /> : option}
            </Box>
          </GridItem>
        );
      })}
    </>
  );
};

export default DataLine;
