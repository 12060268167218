import { FC } from "react";

import { HStack, Text } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";

import { PriceTag } from "@components/shoppingCart/PriceTag";
import { IProduct } from "@features/types";

interface IProductPriceTagProps {
  product: IProduct;
}

const ReferralProductPriceTag: FC<IProductPriceTagProps> = ({ product }) => {
  const { t } = useTranslation();
  const { discountPercentage, price, originalPrice } = product;
  const hasDiscount = discountPercentage > 0;

  return (
    <HStack spacing="2" color="blackAlpha.600" fontSize="textSize.regular">
      {hasDiscount && (
        <Text fontSize="textSize.regular" fontWeight="medium" color="blackAlpha.700">
          {t("common:from")}
        </Text>
      )}

      <PriceTag
        currency={price.currency}
        originalPrice={originalPrice?.current}
        currentPrice={price.current}
        salePriceDirection="row"
        rootProps={{
          align: "center",
          spacing: "2",
        }}
        priceProps={{
          color: originalPrice?.current ? "gray.400" : "black",
          fontWeight: "semibold",
          fontSize: "textSize.size24",
          fontStyle: "normal",
        }}
        salePriceProps={{
          color: "blackAlpha.700",
          fontWeight: "medium",
        }}
      />
    </HStack>
  );
};

export default ReferralProductPriceTag;
