import React, { FC } from "react";

import { Divider, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";

import { IBreadcrumbItemType } from "@components/PageBreadcrumb/PageBreadcrumb";
import { DEFAULT_LOCALE } from "@configs/translations";
import { useAppStaticPath } from "@context/AppStaticPathContext";
import { IBlogCategory } from "@features/types";
import useDateFormat from "@utils/hooks/useDateFormat";
import { isEmpty } from "@utils/utils";
import PageBreadcrumb from "components/PageBreadcrumb";

import BlogPagination from "./BlogPagination";
import LatestPost from "./LatestPost";
import PopularPost from "./PopularPost";
import Post from "./Post";
import { IBlogPosts, TBlogItem } from "./types";

interface IBlogListProps {
  data?: IBlogPosts;
  currentCategory?: string;
  isPageBlogLanding?: boolean;
  activeCategory?: IBlogCategory | undefined;
}

const BlogList: FC<IBlogListProps> = ({
  data = {} as IBlogPosts,
  currentCategory,
  isPageBlogLanding,
  activeCategory,
}) => {
  const { page, popularItems, items, pages, newItems } = data;
  const formatDate = useDateFormat();
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { blogPath } = useAppStaticPath();

  const isFirstPage = page === 1;
  const canDisplayPopularPosts = popularItems && popularItems.length > 0;
  const canDisplayPosts = items && items.length > 0;
  const canShowPagination = pages && pages > 1;
  const firstPageRequiredItems = isFirstPage ? [newItems, popularItems] : [];
  const hasNoBlogPosts = [...firstPageRequiredItems, items].every((dataItem) => isEmpty(dataItem));

  // Render latest post
  const renderLatestPost = ({ blog, title, slug }: TBlogItem) => {
    const { blogCategory, publishedAt, images, summary } = blog;

    return (
      <Flex>
        <LatestPost
          category={blogCategory}
          imageSrc={images?.[0]?.path}
          title={title}
          shortSummary={summary}
          publishDate={formatDate(publishedAt, "fullMonthDayYear")}
          slug={slug}
        />
      </Flex>
    );
  };

  // Rendering popular posts
  const renderPopularPosts = (popularPosts: TBlogItem[]) => (
    <SimpleGrid columns={{ base: 1, lg: 2 }} gap={8}>
      {popularPosts.map(({ blog, title, slug }) => {
        const { blogCategory, publishedAt, images, summary } = blog;

        return (
          <PopularPost
            key={slug}
            category={blogCategory}
            imageSrc={images?.[0]?.path}
            title={title}
            shortSummary={summary}
            publishDate={formatDate(publishedAt, "fullMonthDayYear")}
            slug={slug}
          />
        );
      })}
    </SimpleGrid>
  );

  // NOTE: If posts are lower than 8 - simple list.
  // render blog posts
  const renderBlogPosts = (posts: TBlogItem[]) => (
    <SimpleGrid columns={{ base: 1, md: isFirstPage && isPageBlogLanding && posts.length < 8 ? 1 : 2 }} gap={8}>
      {posts.map(({ blog, title, slug }) => {
        const { blogCategory, publishedAt, images, popular: isPopular } = blog;

        return (
          <Post
            key={slug}
            category={blogCategory}
            imageSrc={images?.[0]?.path}
            title={title}
            publishDate={formatDate(publishedAt, "fullMonthDayYear")}
            slug={slug}
            isPopular={isPopular}
          />
        );
      })}
    </SimpleGrid>
  );

  const breadcrumbItems: IBreadcrumbItemType[] = [
    { title: t("navigation:home"), link: `${locale !== DEFAULT_LOCALE ? `/${locale}` : "/"}` },
    { title: t("navigation:blog"), link: blogPath.replace("/", ""), current: currentCategory === "blog" },
  ];

  if (activeCategory) {
    breadcrumbItems.push({ title: activeCategory.name || "Category", link: "", current: true });
  }

  return (
    <>
      <PageBreadcrumb breadcrumbItems={breadcrumbItems} />
      {hasNoBlogPosts && <Text color="gray.500">{t("no-data")}</Text>}
      {isFirstPage && (
        <>
          {newItems?.[0] && renderLatestPost(newItems[0])}
          {canDisplayPopularPosts && renderPopularPosts(popularItems)}
          {(newItems?.[0] || canDisplayPopularPosts) && <Divider borderColor="blackAlpha.300" />}
        </>
      )}
      {canDisplayPosts && renderBlogPosts(items)}
      {canShowPagination && (
        <>
          <Divider borderColor="blackAlpha.300" />
          <BlogPagination currentPage={page ?? 0} numberOfPages={pages} pathname={currentCategory} />
        </>
      )}
    </>
  );
};

export default BlogList;
