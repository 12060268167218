import { SystemProps } from "@chakra-ui/react";

import { border } from "../styles";

export const gridItemBaseStyles = {
  title: {
    base: {
      colSpan: 1,
      borderBottom: "none",
      gridColumn: "1 / -1",
      pb: 0,
    },
    md: {
      colSpan: 1,
      gridColumn: "1 / 4",
    },
  },
  item: {
    base: {},
    md: {
      colSpan: 2,
    },
  },
};

export const gridStyles = {
  placeholder: {
    p: 0,
  },
  heading: {
    description: {
      base: {
        ...gridItemBaseStyles.title.base,
        pb: 2,
      },
      md: {
        ...gridItemBaseStyles.title.md,
      },
    },
    plan: {
      base: {
        ...gridItemBaseStyles.item.base,
        textAlign: "center" as SystemProps["textAlign"],
        pb: 4,
      },
      md: {
        ...gridItemBaseStyles.item.md,
      },
    },
  },
  list: {
    title: {
      base: {
        ...gridItemBaseStyles.title.base,
        textAlign: "center" as SystemProps["textAlign"],
      },
      md: {
        ...gridItemBaseStyles.title.md,
        textAlign: "inherit" as SystemProps["textAlign"],
      },
    },
    item: {
      base: {
        ...gridItemBaseStyles.item.base,
      },
      md: {
        ...gridItemBaseStyles.item.md,
      },
    },
  },
  border: {
    base: {
      ...border,
      py: 4,
    },
    md: {
      ...border,
      py: 5,
    },
  },
};
