import React, { FC, ReactNode } from "react";

import { Box } from "@chakra-ui/react";

import { VERTICAL_ALIGN_VALUE } from "@components/ResourceView/components/constants";
import { isNullOrUndefined } from "@utils/utils";
import { IBlock } from "features/cms/types";
import borderRadius from "theme/foundations/borders";

interface IBlockProps {
  block: IBlock;
  children: ReactNode;
}

const Block: FC<IBlockProps> = ({ block, children }) => {
  return (
    <Box
      key={block.code}
      alignSelf={VERTICAL_ALIGN_VALUE[block.verticalAlign || "top"]}
      display="flex"
      alignItems="center"
      paddingX={{
        base: block.removeBlockSidePaddingMobile ? 0 : 2,
        md: block.removeBlockSidePadding ? 0 : 4,
        xl: block.removeBlockSidePadding ? 0 : 4,
      }}
      paddingY={{
        base: 2,
        md: 4,
        xl: 6,
      }}
      width={{ base: "full", md: `${block.width}%` }}
      bgColor={block.backgroundColor}
      {...(block.roundedBlockBorder
        ? {
            borderRadius: borderRadius.radii.md,
          }
        : {})}
      textAlign={{ base: block.centerTextContentForMobile ? "center" : "unset", md: "unset" }}
      {...(!isNullOrUndefined(block.minHeight)
        ? {
            minHeight: block.minHeight,
          }
        : {})}
      sx={{
        ...(block.backgroundColor
          ? {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              "--block-bg-color": block.backgroundColor,
            }
          : {}),
        a: {
          height: "auto",
          whiteSpace: "break-spaces",
        },
      }}
    >
      <Box w="full">{children}</Box>
    </Box>
  );
};

export default Block;
