/* eslint-disable react/no-array-index-key */
import { FC } from "react";

import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";

import { formatPrice } from "@utils/helpers/helpers";

type TCard = {
  id: string | number;
  title: string;
  subtitle?: string;
  image: string | null;
  content?: string[];
  price: {
    currency: string;
    current: number;
    note?: string;
  };
  description?: string;
  color?: string;
};

const Card: FC<TCard> = ({ id, title, subtitle, image, description, color, price, content }) => {
  const { t } = useTranslation();

  const colorBorder = {
    height: "6px",
    width: "124px",
    borderRadius: "0 0 10px 10px",
    backgroundColor: color,
    content: "''",
    display: "block",
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
  };

  return (
    <VStack
      borderRadius="10px"
      lineHeight={1.4}
      width="full"
      position="relative"
      textAlign="center"
      justifyContent="space-between"
      bg="gray.50"
      p={4}
      py={6}
      _before={color ? colorBorder : {}}
    >
      <Box width="full">
        <Text px={4} fontSize="textSize.regular" fontWeight="medium">
          {title}
        </Text>
        {subtitle && (
          <Text pt={1} fontSize="textSize.labels">
            {subtitle}
          </Text>
        )}

        {image && (
          <Flex pt={2} alignItems="center" justifyContent="center">
            <Image width="120" height="120" src={image} alt={title} />
          </Flex>
        )}

        {content && (
          <Box width="full" pt={2}>
            <Text color="gray.500" fontSize="textSize.labels">
              {t("compare:products.pack-content")}
            </Text>
            {content.map((item, index) => (
              <Box
                mt={2}
                p={1}
                lineHeight={1.4}
                w="full"
                borderRadius="10px"
                border="1px solid"
                borderColor="gray.200"
                key={`content-${id}-${item}-${index}`}
              >
                {item}
              </Box>
            ))}
          </Box>
        )}

        {description && (
          <Text lineHeight={1.4} pb={3} fontSize="textSize.labels" pt={2}>
            {description}
          </Text>
        )}
      </Box>

      <Box color="gray.500" fontWeight="medium" fontSize="textSize.regular" pt={4}>
        <Text>
          {price.current > 0 &&
            formatPrice(price.current, {
              currency: price.currency,
            })}

          {!price.current && t("free")}
        </Text>

        {price.note && <Text>{price.note}</Text>}
      </Box>
    </VStack>
  );
};

export default Card;
