import { FC } from "react";

import { Box, GridItem, useBreakpointValue } from "@chakra-ui/react";

import FeatureStatus from "./FeatureStatus";
import { gridStyles } from "./styles";

type TDataLine = {
  key: string;
  title: string;
  plans: {
    enabled: boolean;
    color: string;
  }[];
};

const DataLine: FC<TDataLine> = ({ key, title, plans }) => {
  const titleGridItemStyles = useBreakpointValue(gridStyles.list.title);
  const gridItemStyles = useBreakpointValue(gridStyles.list.item);
  const borderStyles = useBreakpointValue(gridStyles.border);

  return (
    <>
      <GridItem {...borderStyles} {...titleGridItemStyles}>
        <Box>{title}</Box>
      </GridItem>

      {plans.map((plan, index) => (
        // Use index to avoid duplicate keys
        // eslint-disable-next-line react/no-array-index-key
        <GridItem {...borderStyles} {...gridItemStyles} key={`${key}-feature-${index}`}>
          <Box w="full" display="flex" justifyContent="center" color={plan.color}>
            <FeatureStatus isEnabled={plan.enabled} />
          </Box>
        </GridItem>
      ))}
    </>
  );
};

export default DataLine;
