import { FC } from "react";

import { VStack, Text } from "@chakra-ui/react";

import { Heading } from "@components/Heading/Heading";
import { parseHtml } from "@components/HTMLParser/utils";

interface IPageHeaderProps {
  title: string;
  subtitle?: string | false | undefined;
  description?: string | false | undefined;
  dark?: boolean;
}

const PageHeader: FC<IPageHeaderProps> = ({ title, subtitle, description, dark }) => (
  <VStack spacing={2} marginBottom={dark ? 0 : 4} paddingX={4} {...(dark ? { backgroundColor: "gray.800" } : {})}>
    <Heading
      data-test-id="pageTitle"
      as="h1"
      textAlign="center"
      {...(subtitle || description ? { marginBottom: { base: 4, md: 8 } } : {})}
      {...(dark ? { color: "white" } : {})}
    >
      {title}
    </Heading>
    {subtitle && (
      <Heading
        data-test-id="pageSubtitle"
        as="h3"
        textTransform="uppercase"
        textAlign="center"
        fontSize="22px"
        lineHeight="29px"
      >
        {parseHtml(subtitle)}
      </Heading>
    )}
    {description && (
      <Text
        data-test-id="pageDescription"
        color="blackAlpha.600"
        textAlign="center"
        fontSize={{ base: "14px", md: "16px" }}
        lineHeight={{ base: "18px" }}
      >
        {description}
      </Text>
    )}
  </VStack>
);

export default PageHeader;
