/* eslint-disable max-len */
import { FC } from "react";

import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Text } from "@chakra-ui/react";

import { TProductsComparison } from "@modules/Compare/types";
import { isBrowser } from "@utils/utils";

import { TTabOnChange } from "../Tabs";
import Card from "./Card";
import CompareButton from "./CompareButton";
import Grid from "./Grid";

type TGroupProps = {
  taxon: string;
  handleTabChange?: TTabOnChange;
  data: TProductsComparison["products"];
};

const Group: FC<TGroupProps> = ({ data, handleTabChange, taxon }) => {
  return (
    <AccordionItem borderBottom="none">
      <AccordionButton position="static">
        {data.name}
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <Text fontSize="textSize.labels" mb={6}>
          {data.description}
        </Text>

        <Grid>
          {data.products.map((product) => {
            const image = product.images[0]?.path || null;

            return (
              <Card
                key={product.slug}
                id={`product-${product.slug}`}
                color={product.plan ? product.planColor : undefined}
                title={product.alternativeTitle ?? product.name}
                subtitle={product.bundleDescription}
                image={image}
                description={product.description || product.alternativeTitle}
                price={product.price}
                content={product.productBundleItems?.items.map((item) => item.product.name)}
              />
            );
          })}
        </Grid>

        {handleTabChange && (taxon === "plans" || taxon === "devices") && (
          <Flex flex={1} justifyContent={{ base: "center", md: "start" }}>
            <CompareButton
              type={taxon}
              onClick={() => {
                if (taxon === "plans") {
                  handleTabChange(0);
                }

                if (taxon === "devices") {
                  handleTabChange(1);
                }

                if (!isBrowser()) {
                  return;
                }

                // NOTE: sometimes it do not scrolls to top. By adding `setTimeout` fixes it.
                setTimeout(() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }, 300);
              }}
            />
          </Flex>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default Group;
