import { FC } from "react";

import { useBreakpointValue, Link as ChakraLink, LinkProps } from "@chakra-ui/react";
import Link from "next/link";

interface ICategoryTag {
  href?: string;
  name?: string;
  isLatest?: boolean;
  linkProps?: LinkProps;
}

const CategoryTag: FC<ICategoryTag> = ({ href, name, isLatest, linkProps }) => {
  const latestPostCategoryColor = useBreakpointValue({ base: "white", md: "gray.300" }, { ssr: true });
  const fontColor = isLatest ? latestPostCategoryColor : "gray.500";
  const fontSize = isLatest ? "textSize.bodyText" : "textSize.labels";

  if (!name) {
    return null;
  }

  const categoryName = (
    <ChakraLink
      as="span"
      color={fontColor}
      fontWeight="bold"
      fontSize={fontSize}
      textTransform="uppercase"
      mb={4}
      {...linkProps}
    >
      {name}
    </ChakraLink>
  );

  return href ? (
    <Link href={href} passHref scroll>
      {categoryName}
    </Link>
  ) : (
    categoryName
  );
};

export default CategoryTag;
