import { FC } from "react";

import { Flex, useBreakpointValue } from "@chakra-ui/react";
import { LinkProps } from "next/link";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

import { generatePagination } from "../utils";
import PaginationItem from "./PaginationItem";

interface IPaginationProps {
  numberOfPages: number;
  currentPage: number;
  pathname?: string;
  locale?: string;
}

const BlogPagination: FC<IPaginationProps> = ({ numberOfPages, currentPage, pathname, locale }) => {
  const visibleSiblingsCount = useBreakpointValue({ base: 0, sm: 1 });

  if (numberOfPages < 1) {
    return null;
  }

  const pagination = generatePagination({
    currentPage,
    numberOfPages,
    visibleSiblingsCount,
  });
  const isFirst = currentPage === 1;
  const isLast = currentPage === numberOfPages;

  const buildLinkProps = (canBuildProps: boolean, page: number): LinkProps | undefined =>
    canBuildProps
      ? {
          href: `/${pathname}/${page}`,
          locale,
        }
      : undefined;

  return (
    <Flex justifyContent={{ base: "center", sm: "start" }} flexWrap={{ base: "wrap", sm: "unset" }}>
      <PaginationItem isDisabled={isFirst} linkProps={buildLinkProps(!isFirst, currentPage - 1)}>
        <FiArrowLeft />
      </PaginationItem>
      {pagination.map((paginationPage, index) => {
        const isDot = paginationPage < 0;
        const itemLinkProps: LinkProps | undefined = buildLinkProps(!isDot, paginationPage);

        return (
          <PaginationItem
            // NOTE: Due `dots` value `-1` we need to use `index` for key
            // eslint-disable-next-line react/no-array-index-key
            key={`pagination-page-${index}`}
            isActive={paginationPage === currentPage}
            isDisabled={paginationPage === currentPage}
            isDot={paginationPage === -1}
            linkProps={itemLinkProps}
          >
            {isDot ? "..." : paginationPage}
          </PaginationItem>
        );
      })}
      <PaginationItem isDisabled={isLast} linkProps={buildLinkProps(!isLast, currentPage + 1)}>
        <FiArrowRight />
      </PaginationItem>
    </Flex>
  );
};

export default BlogPagination;
