import { FC, useEffect, useState } from "react";

import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";

import { IPageData } from "@components/ResourceView/types";
import { COMPARE_TAB_TO_INDEX } from "@modules/Compare";
import { QUERY_PARAM } from "@utils/constants";
import { stringifyToSearchParams } from "@utils/query-utils";

import VehicleSelectorPageLayout from "../layouts/VehicleSelectorPageLayout";

const Compare = dynamic(() => import("@modules/Compare"), { ssr: false });

interface ICompareLandingProps {
  pageData: IPageData;
}

const CompareLanding: FC<ICompareLandingProps> = ({ pageData }) => {
  const { compareData } = pageData;
  const { t } = useTranslation();
  const router = useRouter();
  const url = new URL(router.asPath, "http://localhost");
  const compareTab = url.searchParams.get("compareTab");

  const [currentTabIndex, setCurrentTabIndex] = useState<number>(
    compareTab ? COMPARE_TAB_TO_INDEX[compareTab] : undefined
  );

  const compareTabKey = Object.entries(COMPARE_TAB_TO_INDEX).find(([, key]) => key === currentTabIndex)?.[0];
  const compareTabQuery = stringifyToSearchParams(
    { [QUERY_PARAM.COMPARE_TAB]: compareTabKey },
    { addQueryPrefix: false }
  );

  const vehicleMakes = pageData.vehicleMakes || [];
  const currentVehicle = vehicleMakes.find((vehicle) => vehicle.voltasMappingName === pageData.selectedVehicleBrand);

  useEffect(() => {
    if (compareTab) {
      setCurrentTabIndex(COMPARE_TAB_TO_INDEX[compareTab]);
    }
  }, [compareTab]);

  // Vehicle layout title and subtitle texts
  const layoutTexts: { title: string; subtitle?: string; description?: string } = currentVehicle
    ? { title: t("compare:selected-vehicle", { carBrand: currentVehicle.title }) }
    : {
        title: t("compare:landing-title"),
        subtitle: t("compare:landing-subtitle"),
        description: t("compare:landing-description"),
      };

  return (
    <VehicleSelectorPageLayout
      pageData={pageData}
      {...layoutTexts}
      query={pageData.selectedVehicleBrand ? compareTabQuery : undefined}
      contentMarginTop={8}
    >
      {currentVehicle && compareData && (
        <Compare
          key={currentVehicle.voltasMappingName}
          {...compareData}
          currentTabIndex={currentTabIndex}
          onTabChange={setCurrentTabIndex}
        />
      )}
    </VehicleSelectorPageLayout>
  );
};

export default CompareLanding;
