/* eslint-disable max-len */
import { FC, ReactNode } from "react";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  AspectRatio,
  Box,
  Button,
  Grid,
  GridItem,
  SystemProps,
  Link,
  Flex,
  Divider,
  VStack,
  Text,
} from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import { useRouter } from "next/router";

import { Heading } from "@components/Heading/Heading";
import { parseHtml } from "@components/HTMLParser/utils";
import { DEFAULT_LOCALE } from "@configs/translations";
import { useAppStaticPath } from "@context/AppStaticPathContext";
import { TRANSLATION_DOMAIN } from "@utils/constants";
import { formatPrice } from "@utils/helpers/helpers";

import { TProductDevices } from "../../types";
import Layout from "../Layout";
import { border } from "../styles";
import DataLine from "./DataLine";

const DeviceTitle: FC<{
  title: string;
}> = ({ title }) => {
  return (
    <GridItem
      py={4}
      textAlign={{
        base: "inherit",
        md: "center",
      }}
      fontSize="22px"
      fontWeight="bold"
    >
      {title}
    </GridItem>
  );
};
const DeviceLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation();

  return <Layout title={t("compare:devices.title")}>{children}</Layout>;
};

const styles = {
  note: {
    textAlign: "center" as SystemProps["textAlign"],
    fontSize: "xs",
    color: "gray.500",
    py: 4,
  },
};

const Devices: FC<{
  data: TProductDevices;
}> = ({ data }) => {
  const { t } = useTranslation(TRANSLATION_DOMAIN.COMMON);
  const { locale } = useRouter();

  const { getProductPath } = useAppStaticPath();

  if (!data.devices || data.devices.length === 0) {
    return (
      <DeviceLayout>
        <Text color="gray.500">{t("compare:no-devices-found")}</Text>
      </DeviceLayout>
    );
  }

  const devices = Object.values(data.devices);
  const devicesKeys = Object.keys(data.devices);

  const columnGap = {
    base: 4,
    md: 8,
  };

  const getSupportedVehicles = () => {
    return data["supported-vehicles"].map((attribute) =>
      devicesKeys.map((key) => (attribute.availableFor?.includes(key) ? attribute.name : ""))
    );
  };

  const getHardwareAttributes = () => {
    return data.attributes.map((attribute) => devicesKeys.map((key) => attribute.availableFor?.[key] ?? ""));
  };

  const supportedVehicles = getSupportedVehicles();
  const canDisplaySupportedVehicle = supportedVehicles.some(
    (vehicles) => vehicles.filter((vehicle) => vehicle).length > 0
  );
  const hardwareAttributes = getHardwareAttributes();
  const canDisplayHardware = hardwareAttributes.some(
    (hardwareAttribute) => hardwareAttribute.filter((attribute) => attribute).length > 0
  );

  const gridTemplate = `repeat(${devices.length}, minmax(150px, 1fr))`;

  const renderGridLineData = (data: string[][]) => (
    <Grid templateColumns={gridTemplate}>
      {data.map((dataItems, index) => {
        // If all list attributes are `empty` - nothing to display for user.
        if (dataItems.every((dataItem) => !dataItem)) {
          return null;
        }

        return (
          <DataLine
            // eslint-disable-next-line react/no-array-index-key
            key={`grid-line-data-${index}`}
            options={dataItems}
          />
        );
      })}
    </Grid>
  );

  return (
    <DeviceLayout>
      <Box overflowX="auto">
        <Box display="grid">
          <Grid {...border} columnGap={columnGap} templateColumns={gridTemplate}>
            {devices.map((device) => (
              <DeviceTitle key={`title-${device.code}`} title={device.name} />
            ))}
          </Grid>

          <Grid py={6} columnGap={columnGap} templateColumns={gridTemplate}>
            {devices.map((device) => (
              <GridItem key={`device-image-${device.code}`} display="flex" flexDir="column">
                <AspectRatio
                  borderRadius="10px"
                  overflow="hidden"
                  bg="gray.50"
                  width="full"
                  height={280}
                  ratio={16 / 9}
                >
                  <Box position="absolute" width="full" height="full">
                    <Image
                      style={{
                        objectFit: "contain",
                      }}
                      fill
                      alt=""
                      src={device.images[0].path}
                    />
                  </Box>
                </AspectRatio>

                <VStack flex={1}>
                  <Box pt={6} h="full">
                    <Box>{device.description}</Box>
                    {device.additionalCondition && (
                      <Box pt={6} fontSize="xs" color="gray.500">
                        {parseHtml(device.additionalCondition)}
                      </Box>
                    )}
                  </Box>

                  <Flex align="center" direction="column">
                    <Box pt={6} fontWeight="medium" fontSize="lg">
                      {t("compare:devices.from")}{" "}
                      {formatPrice(device.price.current, {
                        currency: device.price.currency,
                      })}
                    </Box>
                    <Box pt={2}>
                      <Button
                        as={Link}
                        textDecoration="none"
                        href={`${locale !== DEFAULT_LOCALE ? `/${locale}` : ""}${getProductPath(device.slug)}`}
                        size="lg"
                      >
                        {t("buy")}
                      </Button>
                    </Box>
                  </Flex>
                </VStack>
              </GridItem>
            ))}
          </Grid>

          {canDisplayHardware && (
            <>
              <Heading w="full" pb={3} as="h4">
                {t("compare:devices.hardware")}
              </Heading>
              <Divider color="gray.200" opacity={1} />
              {renderGridLineData(hardwareAttributes)}
            </>
          )}

          {canDisplaySupportedVehicle && (
            <>
              <Heading mt={10} pb={3} as="h4">
                {t("compare:devices.supported-vehicles")}
              </Heading>
              <Divider color="gray.200" opacity={1} />
              {renderGridLineData(supportedVehicles)}
            </>
          )}

          <Grid templateColumns={gridTemplate}>
            {devices.map((device) => (
              // Render `GridItem` even `futureNotes` do not exist to prevent rendering data in wrong place.
              <GridItem key={`support-note-${device.code}`} {...styles.note}>
                {device.futureNotes && <Box>{parseHtml(device.futureNotes)}</Box>}
              </GridItem>
            ))}
          </Grid>

          <Grid mt={6} templateColumns={gridTemplate}>
            {devices.map((device) => (
              <GridItem key={`more-info-${device.code}`} textAlign="center">
                <Button
                  href={getProductPath(device.slug)}
                  as={Link}
                  rightIcon={<ArrowForwardIcon />}
                  variant="clean"
                  whiteSpace="break-spaces"
                >
                  {t("compare:devices.learn-more")}
                </Button>
              </GridItem>
            ))}
          </Grid>
        </Box>
      </Box>
    </DeviceLayout>
  );
};

export default Devices;
