import { FC } from "react";
import * as React from "react";

import { Box, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";

import MileageIcon from "@assets/icons/Mileage.svg";
import VehicleSearchIcon from "@assets/icons/VehicleSearch.svg";
import { IVehicleLookUp } from "@features/vehicles/types";

type TAssistantResults = {
  data: IVehicleLookUp;
  vin: string;
};

const AssistantResults: FC<TAssistantResults> = ({ data, vin }) => {
  const { t } = useTranslation();

  if (!(data && data?.data && data.data.faults.length > 0)) {
    return null;
  }

  return (
    <Box backgroundColor="white">
      <Heading as="h2">{t("vin:report")}</Heading>

      <HStack paddingTop="14px">
        <Text color="common.black" fontWeight="510" fontSize="textSize.bodyText" lineHeight="20px">
          {data.metadata.vehicleBases[0].make || ""} {data.metadata.vehicleBases[0].model || ""}
        </Text>
      </HStack>

      <HStack borderBottom="1px solid #1111111F" paddingBottom="24px">
        <Text color="common.text">
          {data.metadata.vehicleBases[0].year || ""} {vin}
        </Text>
      </HStack>

      <HStack borderBottom="1px solid #1111111F" paddingBottom="24px" paddingTop="24px">
        <VStack minW="65px" alignItems="start">
          <MileageIcon />
        </VStack>

        <VStack alignItems="start">
          <HStack>
            <Text color="common.text">{t("vin:expected-mileage")}</Text>
          </HStack>

          <HStack marginTop="0px !important">
            <Heading as="h3" fontSize="textSize.regular" color="common.black">
              {data.data.averageMileage || "N/A"}
            </Heading>
          </HStack>
        </VStack>
      </HStack>

      <HStack paddingTop="24px">
        <VStack minW="65px" alignItems="start">
          <VehicleSearchIcon />
        </VStack>

        <VStack>
          <Heading as="h3" fontSize="textSize.regular" color="common.black" fontWeight="590" lineHeight="24px">
            {t("vin:identify-issues")}
          </Heading>
        </VStack>
      </HStack>

      {data.data.faults.map((item) => {
        return (
          <HStack
            key={item.range}
            borderBottom="1px solid #1111111F"
            paddingBottom="24px"
            paddingTop="24px"
            flexDirection={{ base: "column", md: "row" }}
          >
            <VStack minW={{ base: "100%", md: "260px" }} alignItems="start">
              <Heading
                as="h3"
                fontSize="textSize.regular"
                color="common.black"
                fontWeight="590"
                lineHeight="24px"
                marginBottom="8px"
              >
                {item.range}
              </Heading>
            </VStack>

            <VStack marginLeft="0 !important">
              <Text fontSize="textSize.labels" fontWeight="400" lineHeight="20px" color="common.text">
                {item.issues}
              </Text>
            </VStack>
          </HStack>
        );
      })}
    </Box>
  );
};

export default AssistantResults;
