import { FC } from "react";

import { CloseIcon } from "@chakra-ui/icons";
import { Box, Container, Divider, Flex, Stack } from "@chakra-ui/react";
import Link from "next/link";
import { useRouter } from "next/router";

import { Heading } from "@components/Heading/Heading";
import HTMLParser from "@components/HTMLParser/HTMLParser";
import Image from "@components/Image";
import { DISTRIBUTOR_SELECTOR_PAGE_SLUG } from "@utils/constants";
import useFallbackImage from "@utils/hooks/useFallbackImage";
import { IOca } from "features/vehicles/types";

type TVehicleOcaDetailsProps = IOca & { pageContent?: React.ReactNode };

export const VehicleOcaDetails: FC<TVehicleOcaDetailsProps> = ({ name, picture, description, pageContent }) => {
  const router = useRouter();
  const { imageSrc, onImageError } = useFallbackImage({
    src: picture,
    fallbackImageType: "oca",
  });

  const backUrl = router?.asPath?.substring(0, router?.asPath?.lastIndexOf("/"));
  const isIframePage = router?.query?.slug === DISTRIBUTOR_SELECTOR_PAGE_SLUG;

  return (
    <Container maxW="container.xl" mt={10} mb={14}>
      {isIframePage && backUrl && (
        <Link href={backUrl}>
          <CloseIcon />
        </Link>
      )}
      <Heading as="h1" textAlign="center">
        {name}
      </Heading>
      <Stack spacing={0} gap={8} mt={8} direction={{ base: "column", md: "row" }}>
        <Box flex={1}>
          <Image
            borderRadius="default"
            src={imageSrc}
            alt={name}
            draggable={false}
            onError={onImageError}
            loading="lazy"
            width="600px"
            height="300px"
          />
        </Box>
        <Flex flex={1}>
          <HTMLParser html={description} />
        </Flex>
      </Stack>
      {pageContent && !isIframePage && (
        <Stack direction="column" mt={8} spacing={8}>
          <Divider borderColor="blackAlpha.300" />
          <Box>
            <Box mx={-4}>{pageContent}</Box>
          </Box>
        </Stack>
      )}
    </Container>
  );
};
