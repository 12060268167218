import { FC } from "react";

import { Box } from "@chakra-ui/react";

import { Heading } from "@components/Heading/Heading";

type TLayout = {
  title: string;
  children: React.ReactNode;
};

const Layout: FC<TLayout> = ({ title, children }) => {
  return (
    <Box py={12}>
      <Heading as="h3" textTransform="uppercase">{title}</Heading>
      {children}
    </Box>
  );
};

export default Layout;
