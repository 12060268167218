import { FC } from "react";

import { Box, Button, Divider, HStack, Text, useToken, VStack } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { useRouter } from "next/router";
import { MdCheck, MdRemove } from "react-icons/md";

import { Heading } from "@components/Heading/Heading";
import { DEFAULT_LOCALE } from "@configs/translations";
import { useAppStaticPath } from "@context/AppStaticPathContext";
import { IPlan } from "@features/types";
import { formatPrice } from "@utils/helpers/helpers";
import { TranslationDomain } from "@utils/types";

interface IPlanCardProps {
  plan: IPlan;
}
const PlanCard: FC<IPlanCardProps> = ({ plan }) => {
  const { t } = useTranslation(TranslationDomain.COMMON);
  const { locale } = useRouter();
  const [blackAlpha400] = useToken("colors", ["blackAlpha.400"]);
  const { productsPath } = useAppStaticPath();
  const isFree = plan.price.current === 0;
  return (
    <VStack
      padding={8}
      paddingTop={0}
      border="1px solid"
      borderColor="blackAlpha.200"
      borderRadius="16px"
      spacing={4}
      minHeight="500px"
      w="full"
      maxWidth="384px"
    >
      <Box width="128px" height="6px" backgroundColor={plan.color} borderRadius="0 0 10px 10px" />
      <Heading textAlign="center" as="h3" textTransform="uppercase" color={plan.color} margin={0}>
        {plan.alternativeTitle ?? plan.title}
      </Heading>
      <Divider color="blackAlpha.200" opacity={1} />
      {plan.description && (
        <Text textAlign="center" fontWeight={500} minHeight="72px">
          {plan.description}
        </Text>
      )}
      <VStack spacing={5} alignItems="start" w="full">
        {plan.features.map(({ isAvailable, name }) => {
          const Icon = isAvailable ? MdCheck : MdRemove;
          const iconColor = isAvailable ? plan.color : blackAlpha400;
          const featureTextColor = isAvailable ? "black" : blackAlpha400;

          return (
            <HStack key={name} spacing={2}>
              <Icon color={iconColor} fontSize="24px" />
              <Text color={featureTextColor}>{name}</Text>
            </HStack>
          );
        })}
      </VStack>
      <VStack w="full" spacing={2} flexGrow={1} justifyContent="end">
        <Divider color="blackAlpha.200" opacity={1} marginTop={4} />
        {isFree ? (
          <VStack height="80px" justifyContent="center">
            <Text textAlign="center" fontSize="34px" fontWeight={700}>
              {t("free")}
            </Text>
          </VStack>
        ) : (
          <VStack spacing={1} alignItems="start" w="full" height="80px">
            <Text color="blackAlpha.600">{t("per-year")}</Text>
            <HStack w="full" justifyContent="space-between">
              <Text fontSize="34px" fontWeight={700}>
                {formatPrice(plan.price.current, { currency: plan.price.currency })}
              </Text>
              {plan.productSlug && (
                <Link
                  href={`${locale !== DEFAULT_LOCALE ? `/${locale}` : ""}${productsPath}/${plan.productSlug}`}
                  passHref
                >
                  <Button w={20} h={12} as="span">
                    {t("buy")}
                  </Button>
                </Link>
              )}
            </HStack>
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};

export default PlanCard;
