import React, { FC } from "react";

import { VStack, StackDivider, Button } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";

import { useSessionUtils } from "@utils/hooks/hooks";
import { activeUrl } from "@utils/utils";

import SideMenuItem from "./SideMenuItem";

type TDesktopSideMenuProps = {
  links: TNavigationLink[];
  logout?: boolean;
};

const DesktopSideMenu: FC<TDesktopSideMenuProps> = ({ links, logout }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { signOut } = useSessionUtils();

  const parseTitle = (link: TNavigationLink) => {
    if (link.titleKey) {
      return t(`navigation:${link.titleKey}`);
    }
    return link.title;
  };

  const parsedActiveUrl = activeUrl(links, router.asPath);

  return (
    <VStack
      display={{ base: "none", lg: "flex" }}
      w={{ base: "full", md: "280px" }}
      borderRadius="8px"
      border="1px solid"
      borderColor="gray.200"
      divider={<StackDivider color="gray.200" />}
      p="4"
      spacing="2"
      position="sticky"
      top="100px"
      flexShrink={0}
      alignItems="stretch"
    >
      {links.map((link) => (
        <SideMenuItem
          key={link.url}
          title={parseTitle(link)}
          isActive={link.code === parsedActiveUrl?.code}
          path={link.url}
        />
      ))}
      {logout && (
        <Button mt={2} colorScheme="gray" w="full" onClick={() => signOut()}>
          {t("navigation:log-out")}
        </Button>
      )}
    </VStack>
  );
};

export default DesktopSideMenu;
