import { FC } from "react";

import { Tabs as CTabs, Tab, TabPanel, TabPanels, TabList } from "@chakra-ui/react";

export type TTab = {
  id: string;
  title: string;
  content: JSX.Element;
};

export type TTabOnChange = (index: number) => void;

export type TTabs = {
  tabs: TTab[];
  index: number;
  onChange?: TTabOnChange;
};

const Tabs: FC<TTabs> = ({ tabs, ...rest }) => {
  return (
    <CTabs isLazy isFitted w="full" {...rest}>
      <TabList>
        {tabs.map((tab) => (
          <Tab key={`tab-title-${tab.id}`} py={4}>
            {tab.title}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab) => (
          <TabPanel px={0} key={`tab-panel-${tab.id}`}>
            {tab.content}
          </TabPanel>
        ))}
      </TabPanels>
    </CTabs>
  );
};

export default Tabs;
