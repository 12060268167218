import { FC, ReactNode } from "react";

import { Container, Stack } from "@chakra-ui/react";
import dynamic from "next/dynamic";

import { IBlogCategory } from "@features/types";
import { useIsMobile } from "@utils/hooks/hooks";

const BlogCategories = dynamic(() => import("@components/blog/BlogCategories"), { ssr: false });

interface IBlogLayoutProps {
  children?: ReactNode;
  categories?: IBlogCategory[];
  /**
   * Render children outside of layout container
   */
  isOutsideContainer?: boolean;
  currentCategory?: string;
  containerChildren?: ReactNode;
}

const BlogLayout: FC<IBlogLayoutProps> = ({
  children,
  isOutsideContainer = false,
  currentCategory,
  categories,
  containerChildren,
}) => {
  const isMobile = useIsMobile();

  return (
    <>
      {isMobile && (
        <BlogCategories categories={categories ?? []} key={currentCategory} currentBlogCategory={currentCategory} />
      )}
      {isOutsideContainer && children}
      <Container
        my={{ base: 10, md: 20 }}
        px={{ base: 4 }}
        display="flex"
        maxW="container.xl"
        flexDir={{ base: "column-reverse", md: "row" }}
        gap={{ base: undefined, md: 8 }}
      >
        <Stack spacing={8} flex={1} maxW="5xl">
          {!isOutsideContainer && children}
          {containerChildren}
        </Stack>
        {!isMobile && (
          <BlogCategories categories={categories ?? []} key={currentCategory} currentBlogCategory={currentCategory} />
        )}
      </Container>
    </>
  );
};

export default BlogLayout;
