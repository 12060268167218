import { FC } from "react";

import { Box, Button, GridItem, Link, useBreakpointValue, VStack } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";

import { DEFAULT_LOCALE } from "@configs/translations";
import { useAppStaticPath } from "@context/AppStaticPathContext";
import { TRANSLATION_DOMAIN } from "@utils/constants";
import { formatPrice } from "@utils/helpers/helpers";

import { TPlan } from "../../types";
import { gridStyles } from "./styles";

const GridHeadings: FC<{ plans: TPlan[] }> = ({ plans }) => {
  const { getProductPath } = useAppStaticPath();
  const { t } = useTranslation(TRANSLATION_DOMAIN.COMMON);
  const { locale } = useRouter();

  const descriptionGridItemStyles = useBreakpointValue(gridStyles.heading.description);
  const gridItemStyles = useBreakpointValue(gridStyles.heading.plan);
  const borderStyles = useBreakpointValue(gridStyles.border);

  const hrStyles = useBreakpointValue({
    base: {
      width: "40px",
      height: "4px",
    },
    md: {
      width: "128px",
      height: "6px",
    },
  });

  return (
    <>
      <GridItem color="gray.500" {...borderStyles} {...descriptionGridItemStyles}>
        {t("compare:plans.heading")}
      </GridItem>

      {plans.map((plan, index) => (
        // Use index to avoid duplicate keys
        <GridItem
          {...borderStyles}
          {...gridItemStyles}
          // eslint-disable-next-line react/no-array-index-key
          key={`${plan.code}-feature-${index}`}
          position="relative"
          _after={{
            ...hrStyles,
            bg: plan.color,
            content: '""',
            display: "block",
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            borderRadius: "10px 10px 0 0",
          }}
        >
          <Box
            textAlign="center"
            fontSize="lg"
            fontWeight="medium"
            w="full"
            lineHeight="1.3"
            display="flex"
            justifyContent="center"
            color={plan.color}
          >
            {plan.alternativeTitle ?? plan.title}
          </Box>
        </GridItem>
      ))}

      {/* Plan pricing */}
      <GridItem {...borderStyles} {...descriptionGridItemStyles} {...gridStyles.placeholder} />
      {plans.map((plan, index) => (
        // Use index to avoid duplicate keys
        <GridItem
          {...gridItemStyles}
          {...borderStyles}
          // eslint-disable-next-line react/no-array-index-key
          key={`${plan.code}-feature-${index}`}
        >
          <VStack py={1} pb={2} gap={1} height="full" justifyContent="center">
            {plan.price.current > 0 && (
              <Box lineHeight="1.1" color="gray.500">
                {t("compare:plans.duration")}
              </Box>
            )}

            <Box lineHeight="1" fontSize="lg" fontWeight="medium">
              {plan.price.current === 0 && t("free")}
              {plan.price.current > 0 && formatPrice(plan.price.current, { currency: plan.price.currency })}
            </Box>

            <Box pt={1}>
              {plan.price.current > 0 && (
                <Button
                  as={Link}
                  href={`${locale !== DEFAULT_LOCALE ? `/${locale}` : ""}${getProductPath(plan.productSlug)}`}
                  size="lg"
                >
                  {t("buy")}
                </Button>
              )}
              {/* To occupy the space for layout */}
              {plan.price.current === 0 && (
                <Button
                  size="lg"
                  style={{
                    visibility: "hidden",
                  }}
                >
                  {t("buy")}
                </Button>
              )}
            </Box>
          </VStack>
        </GridItem>
      ))}
    </>
  );
};

export default GridHeadings;
