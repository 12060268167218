import { FC } from "react";

import { parseHtml } from "@components/HTMLParser/utils";

interface IHTMLParserProps {
  html: string;
}

const HTMLParser: FC<IHTMLParserProps> = ({ html }) => {
  return <>{parseHtml(html)}</>;
};

export default HTMLParser;
