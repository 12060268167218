import React, { FC, ReactNode } from "react";

import { Box, Flex } from "@chakra-ui/react";

import Image from "@components/Image";
import { isNullOrUndefined } from "@utils/utils";
import { ISection } from "features/cms/types";

interface ISectionProps {
  section: ISection;
  children: ReactNode;
}
const Section: FC<ISectionProps> = ({ section, children }) => {
  const {
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    backgroundImageHeight,
    color,
    image,
    mobileBackgroundImageHeight,
    minHeight,
    mobileMinHeight,
    hideImageOnMobile: shouldHideImageOnMobile,
    mobileBgColor,
  } = section.desktop;
  const padding = {
    paddingTop: !isNullOrUndefined(paddingTop) ? `${paddingTop}px` : undefined,
    paddingRight: !isNullOrUndefined(paddingRight) ? `${paddingRight}px` : 4,
    paddingBottom: !isNullOrUndefined(paddingBottom) ? `${paddingBottom}px` : undefined,
    paddingLeft: !isNullOrUndefined(paddingLeft) ? `${paddingLeft}px` : 4,
  };

  const getHeight = (desktopHeight?: number, mobileHeight?: number) => ({
    base: !isNullOrUndefined(mobileHeight) ? `${mobileHeight}px` : "auto",
    md: !isNullOrUndefined(desktopHeight) ? `${desktopHeight}px` : "auto",
  });

  const wrapperMinHeight = getHeight(minHeight, mobileMinHeight);
  const contentContainerMinHeight = getHeight(backgroundImageHeight, mobileBackgroundImageHeight);

  return (
    <Flex
      width="full"
      maxW={section.desktop.sectionFullWidth ? "full" : "container.xl"}
      minHeight={wrapperMinHeight}
      margin="0 auto"
      flexWrap="wrap"
      bgSize="cover"
      bgPos="center"
      position="relative"
      zIndex={0}
      {...(image || (!image && color)
        ? {
            sx: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              "--section-bg-color": image ? "transparent" : color,
            },
          }
        : {})}
    >
      <Box
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        bgColor={{ base: image && shouldHideImageOnMobile ? mobileBgColor : color, md: color }}
        zIndex={0}
        height={contentContainerMinHeight}
        maxHeight="100%"
        margin="auto"
      >
        {image && (
          <Box
            position="absolute"
            left={0}
            right={0}
            top={0}
            bottom={0}
            display={{ base: shouldHideImageOnMobile ? "none" : "block", md: "block" }}
          >
            <Image src={image?.path} objectFit="cover" alt="" loading="lazy" draggable={false} layout="fill" />
          </Box>
        )}
      </Box>
      <Flex
        width="full"
        maxW={section.desktop.contentFullWidth ? "full" : "container.xl"}
        margin="0 auto"
        zIndex={1}
        flexDirection={{ base: section.desktop.reverseBlocksMobile ? "column-reverse" : "column", md: "row" }}
        justifyContent={{ base: "center", md: section.desktop.horizontalBlocksAlignment || "unset" }}
        flex={1}
        flexWrap="wrap"
        {...padding}
        minH={contentContainerMinHeight}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default Section;
