import { FC, PropsWithChildren } from "react";

import { ButtonProps, StyleProps, Button, Box } from "@chakra-ui/react";
import Link, { LinkProps } from "next/link";

type TPaginationBoxProps = ButtonProps & {
  isDot?: boolean;
  linkProps?: LinkProps;
};

const PaginationItem: FC<PropsWithChildren<TPaginationBoxProps>> = ({
  children,
  isActive,
  isDisabled,
  onClick,
  linkProps,
  isDot,
}) => {
  const baseWidthHeight = { base: 10, md: 12 };

  const sharedProps: StyleProps = {
    minW: baseWidthHeight,
    minH: baseWidthHeight,
    borderRadius: "default",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "regular",
    display: "inline-flex",
  };

  if (isDot) {
    return (
      <Box as="span" {...sharedProps}>
        {children}
      </Box>
    );
  }

  const activeBg = isActive ? "blackAlpha.200" : "transparent";

  const content = (
    <Button
      variant="solid"
      colorScheme="gray"
      as="span"
      bg={activeBg}
      isDisabled={isDisabled}
      onClick={onClick}
      cursor="pointer"
      {...sharedProps}
    >
      {children}
    </Button>
  );

  return linkProps ? (
    <Link {...linkProps} passHref scroll>
      {content}
    </Link>
  ) : (
    content
  );
};

export default PaginationItem;
