import { FC, ReactNode } from "react";

import { Button, HStack, Link } from "@chakra-ui/react";

import Image from "@components/Image";
import { stringifyToSearchParams } from "@utils/query-utils";

const ShareButton: FC<{ children?: ReactNode; href: string }> = ({ children, href }) => (
  <Button
    as={Link}
    target="_blank"
    href={href}
    colorScheme="gray"
    h={8}
    position="relative"
    px={0}
    py={0}
    minW={8}
    minH={8}
  >
    {children}
  </Button>
);

interface IShareBlogSocialsProps {
  url: string;
  twitterShareText?: string;
}

const ShareBlogSocials: FC<IShareBlogSocialsProps> = ({ url, twitterShareText }) => {
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php${stringifyToSearchParams({ u: url })}`;
  const twitterUrl = `https://twitter.com/intent/tweet${stringifyToSearchParams({ url, text: twitterShareText })}`;

  return (
    <HStack w="full" spacing={4}>
      <ShareButton href={twitterUrl}>
        <Image src="/assets/icons/Twitter.svg" width="20px" height="20px" alt="twitter" />
      </ShareButton>
      <ShareButton href={facebookUrl}>
        <Image src="/assets/icons/Facebook.svg" width="20px" height="20px" alt="facebook" />
      </ShareButton>
    </HStack>
  );
};

export default ShareBlogSocials;
