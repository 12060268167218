import { FC, ReactNode } from "react";

import { Box, Button, Text, useToken, VStack } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { FaArrowRight } from "react-icons/fa";

import { Heading } from "@components/Heading/Heading";
import { BlockWithImage } from "@components/ResourceView/components/BlockWithImage";
import VehicleSelectorPageLayout from "@components/ResourceView/components/Page/layouts/VehicleSelectorPageLayout";
import { IPageData } from "@components/ResourceView/types";
import { ResourceType } from "@features/cms/types";
import { IFeature } from "@features/types";
import { isEmpty } from "@utils/utils";

interface IFeaturesListPageProps {
  pageName: string;
  pageData: IPageData;
  children: ReactNode;
}

const FeaturesListPage: FC<IFeaturesListPageProps> = ({ pageName, pageData, children }) => {
  const { t } = useTranslation();
  const vehicleMakes = pageData.vehicleMakes || [];
  const currentVehicle = vehicleMakes.find((vehicle) => vehicle.voltasMappingName === pageData.selectedVehicleBrand);
  const features = pageData.featureList || [];
  const [gray50] = useToken("colors", ["gray.50"]);

  const renderFeatureBlock = (f: IFeature, index: number) => (
    <BlockWithImage
      key={f.longTitle}
      block={{
        resourceType: ResourceType.BLOCK,
        backgroundColor: gray50,
        width: 100,
        link: "",
        html: "",
        rawHtml: "",
        image: f.image,
        name: f.longTitle ?? "",
        roundedBlockBorder: true,
        desktopBlockImagePosition: index % 2 === 0 ? "right" : "left",
        verticalAlign: "center",
      }}
      isFeatureBlock
    >
      <Heading as="h4" fontSize="22px">
        {f.longTitle}
      </Heading>
      <Heading as="h6">{f.subTitle}</Heading>
      <Text>{f.description}</Text>
      {f.contentPageSlug && (
        <Link href={`/${f.contentPageSlug}`} passHref>
          <Button variant="link" py={4}>
            {f.linkTitle}{" "}
            <Box as="span" marginLeft={2}>
              <FaArrowRight />
            </Box>
          </Button>
        </Link>
      )}
    </BlockWithImage>
  );

  return (
    <VehicleSelectorPageLayout
      pageData={pageData}
      title={t("features:list-title", { ...(currentVehicle ? { carBrand: currentVehicle?.title } : {}) })}
    >
      {!isEmpty(features) && (
        <VStack spacing={8} marginBottom={20}>
          <Heading as="h3" textTransform="uppercase" margin={0}>
            {pageName}
          </Heading>
          <VStack px={4} spacing={3} w="full" maxW="container.xl">
            {features.map(renderFeatureBlock)}
          </VStack>
        </VStack>
      )}
      {children}
    </VehicleSelectorPageLayout>
  );
};

export default FeaturesListPage;
