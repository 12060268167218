import { format } from "date-fns";

const dateFormats = {
  default: "dd MMM, yyyy",
  fullMonthDayYear: "MMMM dd, yyyy",
} as const;

export interface IDateToFormatParams {
  date: string | Date | number;
  formatKey?: keyof typeof dateFormats;
  locale?: Locale;
}

export const formatDate = ({ date, formatKey = "default", locale }: IDateToFormatParams): string => {
  return format(new Date(date), dateFormats[formatKey], {
    locale,
  });
};
