import { FC } from "react";
import useTranslation from "next-translate/useTranslation";

import { Box, Grid } from "@chakra-ui/react";

import { ISupportedPlans } from "@features/types";

import Layout from "../Layout";
import Feature from "./Feature";
import GridHeadings from "./GridHeading";

const Plans: FC<ISupportedPlans> = ({ plans = [], features = [] }) => {
  const { t } = useTranslation();

  const templateColumns = `repeat(${plans.length}, minmax(100px, 1fr))`;
  const templateColumns1 = `repeat(${plans.length * 2 + 3}, minmax(80px, 1fr))`;

  return (
    <Layout title={t("compare:plans.title")}>
      <Box overflowX="auto">
        <Grid w="full" templateColumns={{ base: templateColumns, md: templateColumns1 }}>
          {plans.length > 0 && <GridHeadings plans={plans} />}
          {features.map((feature) => (
            <Feature
              key={feature.name}
              title={feature.name}
              plans={plans.map((plan) => ({
                enabled: (feature.availableFor ?? []).includes(plan.code),
                color: plan.color,
              }))}
            />
          ))}
        </Grid>
      </Box>
    </Layout>
  );
};

export default Plans;
