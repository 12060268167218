import { useState } from "react";

type TFallbackImageType = "product" | "oca" | "vehicle";

const FALLBACK_IMAGE: Record<TFallbackImageType, string> = {
  oca: "/vehicles/oca_fallback.png",
  vehicle: "/vehicles/vehicle_model_fallback.png",
  product: "/product_fallback.png",
};

interface IUseFallbackImageParams {
  src?: string;
  fallbackImageType: TFallbackImageType;
}

interface IUseFallbackImageReturn {
  imageSrc: string;
  onImageError: () => void;
}

const useFallbackImage = ({ fallbackImageType, src }: IUseFallbackImageParams): IUseFallbackImageReturn => {
  const [isFailed, setIsFailed] = useState<boolean>(false);

  const fallbackImage = `/assets${FALLBACK_IMAGE[fallbackImageType]}`;

  const imgSrc = src ?? fallbackImage;

  return {
    imageSrc: isFailed ? fallbackImage : imgSrc,
    onImageError: () => setIsFailed(true),
  };
};

export default useFallbackImage;
