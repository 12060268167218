import React, { FC } from "react";

import { VStack, HStack, useBreakpointValue } from "@chakra-ui/react";

import PlanCard from "@components/PlanList/components/PlanCard";
import { IPlan } from "@features/types";

interface IPlanListProps {
  plans: IPlan[];
}

const PlanList: FC<IPlanListProps> = ({ plans }) => {
  const isVertical = useBreakpointValue({ base: true, lg: false }, { ssr: true });
  const cards = plans.map((plan) => <PlanCard key={plan.title} plan={plan} />);

  return isVertical ? (
    <VStack spacing={8} w="full" alignItems="center">
      {cards}
    </VStack>
  ) : (
    <HStack spacing={2} justifyContent="space-between" alignItems="stretch">
      {cards}
    </HStack>
  );
};

export default PlanList;
