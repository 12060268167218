import { FC } from "react";

import { Text, TextProps } from "@chakra-ui/react";

interface IPublishDateProps {
  date: string;
  textProps?: TextProps;
}

export const PublishDate: FC<IPublishDateProps> = ({ date, textProps }) => {
  return (
    <Text
      as="time"
      dateTime={date}
      color="gray.500"
      fontWeight="medium"
      fontSize="textSize.regular"
      textTransform="capitalize"
      {...textProps}
    >
      {date}
    </Text>
  );
};

export default PublishDate;
