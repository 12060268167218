import { FC, ReactNode } from "react";

import { Box } from "@chakra-ui/react";

import PageHeader from "@components/PageHeader";
import FullWidthPageContainer from "@components/ResourceView/components/Page/layouts/components/FullWidthPageContainer";
import { IPageData } from "@components/ResourceView/types";
import { VehicleBrandSelector } from "@components/VehicleBrand/VehicleBrandSelector";
import { getVehicleSelectorRoutePath, isEmpty } from "@utils/utils";

interface IVehicleSelectorPageLayoutProps {
  pageData: IPageData;
  children?: ReactNode;
  title: string;
  subtitle?: string;
  description?: string;
  query?: string;
  contentMarginTop?: number;
}

const VehicleSelectorPageLayout: FC<IVehicleSelectorPageLayoutProps> = ({
  pageData,
  children,
  title,
  subtitle,
  description,
  query,
  contentMarginTop = 12,
}) => {
  const { selectedVehicleBrand } = pageData;
  const vehicleMakes = pageData.vehicleMakes || [];

  return (
    <FullWidthPageContainer {...(!children ? { marginBottom: 0 } : {})}>
      <PageHeader title={title} subtitle={subtitle} description={description} />
      {!isEmpty(vehicleMakes) && (
        <VehicleBrandSelector
          isSingleLine={!!selectedVehicleBrand}
          carBrandItems={vehicleMakes.map((v) => ({
            ...v,
            routePath: getVehicleSelectorRoutePath(pageData, v, query),
          }))}
          selectedVehicleBrand={selectedVehicleBrand}
          isAlwaysSticky={false}
        />
      )}
      {children && <Box marginTop={contentMarginTop}>{children}</Box>}
    </FullWidthPageContainer>
  );
};

export default VehicleSelectorPageLayout;
