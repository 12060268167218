import { FC } from "react";

import { Box, Stack, Link as ChakraLink, StyleProps, useBreakpointValue, StackDirection, Flex } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import Link from "next/link";

import { Heading } from "@components/Heading/Heading";
import Image from "@components/Image";
import { THorizontalAlignValue } from "@components/ResourceView/components/constants";
import { IBlogCategory } from "@features/types";
import useDateFormat from "@utils/hooks/useDateFormat";

import CategoryTag from "./CategoryTag";
import PublishDate from "./PublishDate";

const PopularTag = dynamic(() => import("./PopularTag"), { ssr: false });

interface IPostProps {
  category: IBlogCategory;
  imageSrc?: string;
  title: string;
  publishDate: string;
  isPopular?: boolean;
  slug: string;
  shouldApplyBorder?: boolean;
  /**
   * Only used when rendering as component from page builder.
   * Otherwise `publishDate` should come formatted from outside to reduce load impact of importing `date-fns`.
   */
  applyDateFormatter?: boolean;
  applyMobileStyles?: boolean;
  width?: number;
  alignment?: THorizontalAlignValue;
}

const Post: FC<IPostProps> = ({
  imageSrc,
  category,
  publishDate,
  title,
  isPopular,
  slug,
  shouldApplyBorder,
  applyDateFormatter = false,
  applyMobileStyles = false,
  width,
  alignment,
}) => {
  const formatDate = useDateFormat(applyDateFormatter);
  const date = applyDateFormatter ? formatDate(publishDate, "fullMonthDayYear") : publishDate;

  const borderProps: StyleProps | undefined = shouldApplyBorder
    ? { borderRadius: "default", border: "1px solid", borderColor: "blackAlpha.300", p: 4 }
    : undefined;

  const containerDirectionValue = useBreakpointValue<StackDirection>({ base: "column", md: "row" }, { ssr: true });
  const containerDirection: StackDirection | undefined = applyMobileStyles ? containerDirectionValue : "row";

  const imageWidthValue = useBreakpointValue({ base: "full", md: "128px" }, { ssr: true });
  const imageWidth = applyMobileStyles ? imageWidthValue : { base: "99px", md: "128px" };

  const containerAlignmentValue = useBreakpointValue({ base: "center", md: alignment });
  const containerAlignment = applyMobileStyles ? containerAlignmentValue : undefined;

  return (
    <Flex justifyContent={containerAlignment} w="full">
      <Stack
        direction={containerDirection}
        spacing={applyMobileStyles ? 8 : 4}
        {...borderProps}
        maxW={width ? `${width}px` : undefined}
      >
        <Box zIndex={-1} position="relative" h={{ base: "99px", md: "128px" }} w={imageWidth}>
          {imageSrc && (
            <Image
              src={imageSrc}
              layout="fill"
              objectFit="cover"
              alt=""
              borderRadius="default"
              loading="lazy"
              draggable={false}
            />
          )}
          {isPopular && <PopularTag left={2} top={2} right={undefined} />}
        </Box>
        <Stack flex={1} spacing={0} borderBottomRadius="default">
          <Box mb={2}>
            <CategoryTag href={`/${category.slug}`} name={category.name} />
          </Box>
          <Heading fontSize="textSize.regular" as="h5" pb={2}>
            <Link href={`/${slug}`} passHref scroll>
              <ChakraLink as="span">{title}</ChakraLink>
            </Link>
          </Heading>
          <PublishDate
            date={date}
            textProps={{
              color: "black",
              fontWeight: "regular",
              fontSize: "textSize.bodyText",
            }}
          />
        </Stack>
      </Stack>
    </Flex>
  );
};

export default Post;
