import React, { FC, useEffect, useRef } from "react";

import { VStack, StackDivider, HStack, useDisclosure, Text } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";

import { CSS_VARIABLE } from "@utils/constants";
import { activeUrl } from "@utils/utils";

import MenuChevron from "../../../../../assets/icons/MenuChevron.svg";
import SideMenuItem from "./SideMenuItem";

type TMobileSideMenuProps = {
  links: TNavigationLink[];
  mobileMenuTitle?: string;
};

const MobileSideMenu: FC<TMobileSideMenuProps> = ({ links, mobileMenuTitle }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { isOpen, onToggle } = useDisclosure();
  const containerRef = useRef<HTMLDivElement>(null);
  const parsedActiveUrl = activeUrl(links, router.asPath);

  const parseTitle = (link: TNavigationLink) => {
    if (link.titleKey) {
      return t(`navigation:${link.titleKey}`);
    }
    return link.title;
  };

  useEffect(() => {
    const containerHeight = containerRef?.current?.clientHeight || 0;
    const root = document.querySelector<HTMLElement>(":root");

    root?.style.setProperty(
      CSS_VARIABLE.MOBILE_NAV_HEIGHT,
      `calc(var(${CSS_VARIABLE.MAIN_NAV_HEIGHT}, 80px) + ${containerHeight}px)`
    );

    return () => {
      root?.style.removeProperty(CSS_VARIABLE.MOBILE_NAV_HEIGHT);
    };
  }, [containerRef]);

  return (
    <VStack
      display={{ base: "flex", lg: "none" }}
      ref={containerRef}
      w="full"
      borderBottom="1px solid"
      borderColor="gray.200"
      px="4"
      py="6px"
      position="sticky"
      top={{ base: 14, md: 20 }}
      left={0}
      background="white"
      divider={<StackDivider color="gray.200" />}
      zIndex={1}
      height={isOpen ? "100vh" : "auto"}
    >
      <HStack
        onClick={onToggle}
        fontSize="textSize.regular"
        color="gray.400"
        w="full"
        justify="space-between"
        py="2"
        cursor="pointer"
      >
        <Text color="black" fontWeight="normal">
          {mobileMenuTitle || (parsedActiveUrl ? parseTitle(parsedActiveUrl) : null)}
        </Text>
        <HStack spacing={4}>
          <MenuChevron
            style={{
              transform: isOpen ? "rotate(-180deg)" : "none",
              transition: ".3s ease",
            }}
          />
        </HStack>
      </HStack>
      {isOpen && (
        <VStack
          pt={2}
          pb={40}
          spacing="4"
          w="full"
          divider={<StackDivider color="gray.200" />}
          overflowY="scroll"
          alignItems="stretch"
        >
          {links.map((link) => (
            <SideMenuItem
              key={link.url}
              title={parseTitle(link)}
              isActive={link.code === parsedActiveUrl?.code}
              path={link.url}
            />
          ))}
        </VStack>
      )}
    </VStack>
  );
};

export default MobileSideMenu;
