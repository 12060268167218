import React, { FC, ReactNode } from "react";

import { Container, StyleProps } from "@chakra-ui/react";

interface IFullWidthPageContainerProps extends StyleProps {
  children: ReactNode;
  disableMarginY?: boolean;
}

const FullWidthPageContainer: FC<IFullWidthPageContainerProps> = ({ children, disableMarginY, ...rest }) => (
  <Container
    id="FullWidthPageContainer"
    w="full"
    maxW="full"
    marginTop={disableMarginY ? 0 : { base: 10, md: 20 }}
    marginBottom={disableMarginY ? 0 : { base: 10, md: 20 }}
    padding={0}
    {...rest}
  >
    {children}
  </Container>
);

export default FullWidthPageContainer;
