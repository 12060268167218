import { FC, useState } from "react";

import { Container, ExpandedIndex } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";

import { ISupportedPlans } from "@features/types";

import Devices from "./components/Devices";
import Plans from "./components/Plans";
import Products from "./components/Products";
import Tabs from "./components/Tabs";
import { TProductDevices, TProductsComparison } from "./types";

export const COMPARE_TAB_TO_INDEX = {
  plans: 0,
  devices: 1,
  products: 2,
} as const;

export type TCompareTabKey = keyof typeof COMPARE_TAB_TO_INDEX;

interface ICompareProps {
  plans: ISupportedPlans;
  devices: TProductDevices;
  productsComparison: TProductsComparison[];
  currentTabIndex?: number;
  onTabChange?: (index: number) => void;
}

const Compare: FC<ICompareProps> = ({ plans, devices, productsComparison, currentTabIndex, onTabChange }) => {
  const [tabIndex, setTabIndex] = useState(currentTabIndex ?? COMPARE_TAB_TO_INDEX.plans);
  const { t } = useTranslation();
  const [currentTaxons, setCurrentTaxons] = useState<ExpandedIndex>(productsComparison.length > 0 ? [0] : []);

  const handleTabChange = (index: number) => {
    setTabIndex(index);
    onTabChange?.(index);
  };

  const isComparisonVisible = plans && devices && productsComparison;

  return isComparisonVisible ? (
    <Container maxW="container.xl">
      <Tabs
        index={tabIndex}
        onChange={handleTabChange}
        tabs={[
          {
            id: "plans",
            title: t("compare:plans.title"),
            content: <Plans plans={plans.plans} features={plans.features} />,
          },
          {
            id: "devices",
            title: t("compare:devices.title"),
            content: <Devices data={devices} />,
          },
          {
            id: "products",
            title: t("compare:products.title"),
            content: (
              <Products
                data={productsComparison}
                handleTabChange={handleTabChange}
                expandedIndex={currentTaxons}
                onExpand={setCurrentTaxons}
              />
            ),
          },
        ]}
      />
    </Container>
  ) : null;
};

export default Compare;
