import { FC, ReactNode } from "react";

import { Box, Text } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";

import PlanList from "@components/PlanList";
import VehicleSelectorPageLayout from "@components/ResourceView/components/Page/layouts/VehicleSelectorPageLayout";
import { IPageData } from "@components/ResourceView/types";
import { IPlan, IPlanFeature } from "@features/types";
import { TRANSLATION_DOMAIN } from "@utils/constants";

interface IPlansListPageProps {
  pageData: IPageData;
  children: ReactNode;
}

const PlansListPage: FC<IPlansListPageProps> = ({ pageData, children }) => {
  const { t } = useTranslation(TRANSLATION_DOMAIN.PLANS);
  const vehicleMakes = pageData.vehicleMakes || [];
  const currentVehicle = vehicleMakes.find((vehicle) => vehicle.voltasMappingName === pageData.selectedVehicleBrand);
  const planList = pageData.supportedPlans?.plans ?? [];
  const features = pageData.supportedPlans?.features ?? [];

  const plans = planList.map<IPlan>((plan) => {
    const planFeatures = features.map<IPlanFeature>(({ availableFor, name }) => ({
      isAvailable: (availableFor ?? []).includes(plan.code),
      name,
    }));

    return { ...plan, features: planFeatures };
  });

  return (
    <VehicleSelectorPageLayout
      pageData={pageData}
      title={t("plans:list-title", { ...(currentVehicle ? { carBrand: currentVehicle?.title } : {}) })}
    >
      <Box w="full" maxW="container.xl" margin="0 auto" marginBottom={8} px={4}>
        {!plans?.length ? <Text color="gray.500">{t("no-available-plans")}</Text> : <PlanList plans={plans} />}
      </Box>
      {children}
    </VehicleSelectorPageLayout>
  );
};

export default PlansListPage;
