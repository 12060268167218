import React, { FC } from "react";

import { ChevronRightIcon } from "@chakra-ui/icons";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";

import theme from "../../theme";

export interface IBreadcrumbItemType {
  title: string;
  link: string;
  current?: boolean;
}

interface IProductDiscountProps {
  breadcrumbItems: IBreadcrumbItemType[];
  isVisible?: boolean;
}

const PageBreadcrumb: FC<IProductDiscountProps> = ({ breadcrumbItems, isVisible = true }) => {
  if (!isVisible) {
    return null;
  }

  if (breadcrumbItems.length === 0) {
    return null;
  }

  return (
    <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
      {breadcrumbItems.map((item) => (
        <BreadcrumbItem isCurrentPage={item.current} key={item.title}>
          <BreadcrumbLink
            style={{
              textDecoration: item.current ? "none" : "underline",
              cursor: item.current ? "default" : "pointer",
              color: item.current ? theme.colors.common.black : theme.colors.common.text,
            }}
            href={item.link}
            isCurrentPage
          >
            {item.title}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default PageBreadcrumb;
