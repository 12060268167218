import { FC } from "react";

import { Flex, Box, VStack, Spinner, Stack } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";

import { Heading } from "@components/Heading/Heading";
import ReferralProductCard from "@components/product/ReferralProductCard";
import { IProduct } from "@features/types";

type TProductsListProps = {
  products: IProduct[];
  isLoading?: boolean;
  code?: string;
  isCodeValid?: boolean;
};
const ReferralProductsList: FC<TProductsListProps> = ({ products, isLoading, code, isCodeValid }) => {
  const { t } = useTranslation();

  const noProducts = (
    <Heading as="h3" textAlign="center" my="50px">
      {t("product:no-products")}
    </Heading>
  );

  const productsContent = (
    <VStack w="full" align="start" id="products" paddingTop="80px">
      return (
      <Stack spacing={0} direction={{ base: "column", md: "row" }} gap="30px" wrap="wrap" w="full">
        {products.map((product) => (
          <ReferralProductCard key={product.slug} product={product} code={code} isCodeValid={isCodeValid} />
        ))}
      </Stack>
      );
    </VStack>
  );

  const renderContent = () => {
    if (!products || (products && products.length === 0)) {
      return <Box w="full">{noProducts}</Box>;
    }

    return productsContent;
  };

  return (
    <Box position="relative" px={{ base: 0, md: 0 }} w="full">
      {isLoading ? (
        <Flex w="full" h="full" justify="center" alignItems="center" zIndex={1} position="absolute">
          <Spinner size="md" position="absolute" />
          <Flex w="full" h="full" bg="whiteAlpha.600" />
        </Flex>
      ) : (
        renderContent()
      )}
    </Box>
  );
};

export default ReferralProductsList;
