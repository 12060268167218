import { FC } from "react";

import { Box, Text, Stack, Link as ChakraLink } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import Link from "next/link";

import { Heading } from "@components/Heading/Heading";
import Image from "@components/Image";
import { IBlogCategory } from "@features/types";

import CategoryTag from "./CategoryTag";
import PublishDate from "./PublishDate";

const PopularTag = dynamic(() => import("./PopularTag"), { ssr: false });

interface IPopularPostProps {
  category: IBlogCategory;
  imageSrc?: string;
  title: string;
  shortSummary: string;
  publishDate: string;
  slug: string;
}

const PopularPost: FC<IPopularPostProps> = ({ imageSrc, category, publishDate, shortSummary, title, slug }) => {
  return (
    <Stack spacing={0} flex={1} direction="column" as="article" position="relative">
      {imageSrc && (
        <Box position="relative" zIndex={-1} height="224px" width="full">
          <Image
            src={imageSrc}
            objectFit="cover"
            alt=""
            borderTopRadius="md"
            loading="lazy"
            draggable={false}
            layout="fill"
          />
        </Box>
      )}
      <PopularTag />
      <Stack flex={1} spacing={0} bg="gray.50" borderBottomRadius="md" justifyContent="space-between" p={8}>
        <Box>
          <Box mb={4}>
            <CategoryTag href={`/${category.slug}`} name={category.name} />
          </Box>
          <Box pb={8}>
            <Heading fontSize="desktopHeadingSize.4" as="h4" mb={2}>
              <Link href={`${slug}`} passHref scroll>
                <ChakraLink as="span">{title}</ChakraLink>
              </Link>
            </Heading>
            <Text color="gray.500">{shortSummary}</Text>
          </Box>
        </Box>
        <PublishDate
          date={publishDate}
          textProps={{
            color: "black",
            fontWeight: "normal",
            fontSize: "textSize.bodyText",
          }}
        />
      </Stack>
    </Stack>
  );
};

export default PopularPost;
