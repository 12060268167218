import React, { FC, ReactNode } from "react";

import { Stack, VStack, Container } from "@chakra-ui/react";

import DesktopSideMenu from "@components/ResourceView/components/Page/components/DesktopSideMenu";
import MobileSideMenu from "@components/ResourceView/components/Page/components/MobileSideMenu";
import { IPageData } from "@components/ResourceView/types";

type TLegalPagesLayoutProps = {
  children: ReactNode;
  pageData?: IPageData;
};

const LegalPageLayout: FC<TLegalPagesLayoutProps> = ({ children, pageData }) => {
  return (
    <>
      <MobileSideMenu links={pageData?.legalPageSideMenu || []} />
      <Container maxW="container.xl" py={{ base: 20, lg: 12 }} id="container">
        <Stack direction={{ base: "column", lg: "row" }} align="start" spacing={{ base: 8, md: 4, lg: 36 }}>
          <DesktopSideMenu links={pageData?.legalPageSideMenu || []} />
          <VStack w="100%" align="start">
            {children}
          </VStack>
        </Stack>
      </Container>
    </>
  );
};

export default LegalPageLayout;
