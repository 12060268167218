import React, { FC } from "react";

import { Alert, Stack } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";

import CheckIcon from "@assets/icons/Check.svg";
import CrossOutline from "@assets/icons/CrossOutline.svg";

import styles from "./InfoMessage.module.css";

interface IReferralInfoMessageProps {
  message: string;
  isValid?: boolean;
}

const InfoMessage: FC<IReferralInfoMessageProps> = ({ message, isValid }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={3}>
      <Alert variant="referral" marginTop="0px">
        {isValid ? <CheckIcon className={styles.icon} /> : <CrossOutline className={styles.icon} />}
        {t(message)}
      </Alert>
    </Stack>
  );
};

export default InfoMessage;
