import React, { FC, ReactNode } from "react";

import FullWidthPageContainer from "@components/ResourceView/components/Page/layouts/components/FullWidthPageContainer";

type TDefaultLayoutProps = {
  children: ReactNode;
  disableMarginY?: boolean;
};

const ReferralLayout: FC<TDefaultLayoutProps> = ({ children, disableMarginY }) => {
  return <FullWidthPageContainer disableMarginY={disableMarginY}>{children}</FullWidthPageContainer>;
};

export default ReferralLayout;
