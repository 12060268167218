import { FC, useState } from "react";
import * as React from "react";

import { VStack, Text, HStack, Box, Button } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/navigation";

import Img from "@components/Image";
import ReferralProductPriceTag from "@components/product/ProductPriceTag/ReferralProductPriceTag";
import { useAppToast } from "@components/Toast/hooks/UseAppToast";
import { useAppStaticPath } from "@context/AppStaticPathContext";
import { addCouponCode, addCartItem, removeCouponCode } from "@features/cart/cartSlice";
import { IShoppingCart } from "@features/cart/types";
import { IProduct } from "@features/types";
import { ProductPageField } from "@utils/forms/types";
import { useAppDispatch, useServerErrorUtils } from "@utils/hooks/hooks";
import useFallbackImage from "@utils/hooks/useFallbackImage";
import { getCartItemOptions } from "@utils/utils";

import { ProductDiscount, ReferralProductLabels } from "./ProductLabels";

type TProductCardProps = {
  product: IProduct;
  code?: string;
  isCodeValid?: boolean;
};

const ReferralProductCard: FC<TProductCardProps> = ({ product, code, isCodeValid }) => {
  const { discountPercentage, price, originalPrice } = product;
  const hasDiscount = discountPercentage > 0;
  const isDiscountedProduct = originalPrice && originalPrice.current > price.current;

  const router = useRouter();
  const { t } = useTranslation();

  const { cartPath } = useAppStaticPath();

  const { imageSrc, onImageError } = useFallbackImage({
    src: product.images[0].path,
    fallbackImageType: "product",
  });
  const imageSize = { base: "296px", lg: "290px" };

  // ADD TO CART
  const cartItemOptions = getCartItemOptions(product, 1, 1, false, false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { clearErrors, setServerError } = useServerErrorUtils([ProductPageField.QUANTITY, ...[]]);

  const toast = useAppToast();
  const dispatch = useAppDispatch();

  const removeCode = async () => {
    setIsLoading(true);
    const updatedCart = await dispatch(removeCouponCode()).unwrap();

    if (updatedCart) {
      clearErrors();
    }
  };

  const applyCode = async () => {
    setIsLoading(true);
    clearErrors();

    if (code) {
      await dispatch(addCouponCode(code)).unwrap().catch(setServerError);
    }
  };

  const handleAddToCart = async () => {
    setIsLoading(true);
    const productData = {
      productCode: product.code,
      ...cartItemOptions,
    };

    const updatedCart: IShoppingCart | undefined = await dispatch(addCartItem(productData))
      .unwrap()
      .catch(setServerError);

    if (!updatedCart) {
      setIsLoading(false);
      return;
    }

    toast.success({ description: t("cart:item_added_to_cart") });
    const currentCartProductQuantity = updatedCart.items.find((item) => item.product.slug === product.slug)?.quantity;

    if (product.itemsLeft && currentCartProductQuantity && currentCartProductQuantity > product.itemsLeft) {
      toast.error({ description: t("error:quantity-exceeds-limit") });
      setIsLoading(false);
      return;
    }

    await removeCode();
    await applyCode();
    router.push(cartPath);
    setIsLoading(false);
  };

  return (
    <VStack p={{ base: 0, lg: 0 }} minH="482px" w={{ base: "100%", md: "384px" }} maxW={{ base: "500px" }}>
      <VStack
        key={product.code}
        justify="space-between"
        bg="gray.50"
        borderRadius="16px"
        position="relative"
        flexBasis={{ base: "auto", md: "384px" }}
        spacing={6}
        width={{ base: "100%", md: "384px" }}
        minH={{ base: "350px", md: "390px" }}
        p={{ base: 4, lg: 6 }}
      >
        <VStack mt={{ base: 0 }} spacing={{ base: 6, md: 6 }}>
          <VStack
            w={imageSize}
            h={imageSize}
            justify="center"
            position="relative"
            sx={{
              // eslint-disable-next-line @typescript-eslint/naming-convention
              "@media screen and (max-width: 359px)": {
                w: "280px",
                h: "280px",
              },
            }}
          >
            <Img
              key={imageSrc}
              src={imageSrc}
              alt={product.name}
              layout="fill"
              priority
              objectFit="contain"
              objectPosition="center"
              onError={onImageError}
            />
          </VStack>
        </VStack>

        {isCodeValid && !hasDiscount && !isDiscountedProduct && (
          <VStack
            spacing={2}
            bgColor="black"
            color="white"
            borderRadius="0 0 20px 20px"
            width="calc(100%)"
            padding="5px 0"
            position="absolute"
            bottom="0"
          >
            <Text textAlign="center" fontSize="textSize.bodyText" fontWeight="bold" lineHeight="20px">
              {t("product:10-off")}
            </Text>
            <Text fontSize="textSize.small" textAlign="center" lineHeight="16px" marginTop="0 !important">
              {t("product:discount-during-checkout")}
            </Text>
          </VStack>
        )}
        <ReferralProductLabels productLabels={product.productLabels} />
        <ProductDiscount value={product.discountPercentage} isVisible={hasDiscount} dataTestId={product.name} />
      </VStack>
      )
      <HStack width={{ base: "100%", md: "384px" }} padding="16px" alignItems="start" marginTop="0 !important">
        <VStack textAlign="start" width={{ base: "calc(100% - 84px)", md: "calc(300px)" }} alignItems="start">
          <Text
            textAlign="start"
            fontSize="textSize.bodyText"
            fontWeight="medium"
            lineHeight="20px"
            fontStyle="normal"
            color="black"
          >
            {product.name}
          </Text>
          <ReferralProductPriceTag product={product} />
        </VStack>
        <VStack width={{ base: "auto", md: "calc(84px)" }}>
          <Box w="full">
            <Button w="full" onClick={handleAddToCart} disabled={isLoading} isLoading={isLoading}>
              {t("cart:buy-now")}
            </Button>
          </Box>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default ReferralProductCard;
